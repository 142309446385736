<template>
	<div class="app-container">		
		<el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-if="permissionList.includes(permission.add)" v-b-modal.addRow icon="el-icon-plus">{{$t('button.add')}}</el-button>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <!-- <el-button type="success" :loading="buttonloading" @click="getPreviewRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.preview')}}</el-button> -->
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

        <b-modal id="addRow" :title="$t('menu.front_content_item_add')" size="lg" hide-footer @hide="clearDataList()" no-enforce-focus>
			<el-form >
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_code')}}</template>
                            <b-form-input v-model="dataList.code"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_content')}}</template>
                            <el-select v-model="dataList.content_id" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
                                <el-option v-for="(list,index) in contentList" :key="index" :label="list.code" :value="list.id">{{list.code}}</el-option>
                            </el-select>
                        </b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_component')}}</template>
							<el-select v-model="dataList.component" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
								<el-option v-for="(value,key) in componentList" :key="key" :label="key" :value="value">{{key}}</el-option>
							</el-select>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4" v-show="dataList.component =='form'">
							<template slot="label">{{$t('menu.front_form')}}</template>
							<el-select v-model="dataList.refer_id" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
								<el-option v-for="(value,key) in formList" :key="key" :label="value.name" :value="value.id">{{value.name}}</el-option>
							</el-select>
						</b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_style')}}</template>
                            <b-form-textarea v-model="dataList.style" rows="2" max-rows="4"></b-form-textarea>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">Class</template>
                            <b-form-textarea v-model="dataList.class" rows="2" max-rows="4"></b-form-textarea>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_sort')}}</template>
                            <el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
                        </b-form-group>
                    </b-col>
                
                    <b-col cols="12" md="6">
						<b-row>
							<b-col cols="6">
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">xs</template>
									<b-form-input v-model="dataList.xs"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">md</template>
									<b-form-input v-model="dataList.md"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">sm</template>
									<b-form-input v-model="dataList.sm"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">lg</template>
									<b-form-input v-model="dataList.lg"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_status')}}</template>
                            <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
                        </b-form-group>

                        <b-form-group label-cols="6" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_security')}}</template>
                            <b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
                        </b-form-group>
                   </b-col>
                </b-row>
                <el-tabs class="mb-3">
					<el-tab-pane v-for="item in languageList" :key="item.code" :item="item" :label="item.name">
						<el-form-item :label="$t('admin_general.table_content')" class="mb-1">
							<br/>
							<Tinymce ref="editor" v-model="dataList.content[item.id]" :height="400" :disabled="loading" class="mt-3"/>
						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editRow" :title="$t('menu.front_content_item_edit')" size="lg" hide-footer @hide="clearDataList()" no-enforce-focus>
			<el-form >
               <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_code')}}</template>
                            <b-form-input v-model="dataList.code"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_content')}}</template>
                            <el-select v-model="dataList.content_id" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
                                <el-option v-for="(list,index) in contentList" :key="index" :label="list.code" :value="list.id">{{list.code}}</el-option>
                            </el-select>
                        </b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_component')}}</template>
							<el-select v-model="dataList.component" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
								<el-option v-for="(value,key) in componentList" :key="key" :label="key" :value="value">{{key}}</el-option>
							</el-select>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4" v-show="dataList.component =='form'">
							<template slot="label">{{$t('menu.front_form')}}</template>
							<el-select v-model="dataList.refer_id" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
								<el-option v-for="(value,key) in formList" :key="key" :label="value.name" :value="value.id">{{value.name}}</el-option>
							</el-select>
						</b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_style')}}</template>
                            <b-form-textarea v-model="dataList.style" rows="2" max-rows="4"></b-form-textarea>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">Class</template>
                            <b-form-textarea v-model="dataList.class" rows="2" max-rows="4"></b-form-textarea>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_sort')}}</template>
                            <el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
                        </b-form-group>
                    </b-col>
                
                    <b-col cols="12" md="6">
						<b-row>
							<b-col cols="6">
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">xs</template>
									<b-form-input v-model="dataList.xs"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">md</template>
									<b-form-input v-model="dataList.md"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">sm</template>
									<b-form-input v-model="dataList.sm"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">lg</template>
									<b-form-input v-model="dataList.lg"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_status')}}</template>
                            <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
                        </b-form-group>

                        <b-form-group label-cols="6" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_security')}}</template>
                            <b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
                        </b-form-group>
                   </b-col>
                </b-row>

                <el-tabs class="mb-3">
					<el-tab-pane v-for="item in languageList" :key="item.code" :item="item" :label="item.name">
						<el-form-item :label="$t('admin_general.table_content')" class="mb-1">
							<br/>
							<Tinymce ref="editor" v-model="dataList.content[item.id]" :height="400" :disabled="loading" class="mt-3"/>
						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="previewRow" :title="$t('menu.front_content_item_preview')" size="lg" >
			<el-tabs class="mb-3">
				<el-tab-pane style="height:200px;" v-for="language in languageList" :key="language.id" :item="language" :label="language.name">

				</el-tab-pane>
			</el-tabs>
        </b-modal>

	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';
import Tinymce from '@/components/tinymce';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	component:'',
	code:'',
	content_code:'',
	sort:'',
	status:'',
}
			
export default{
	components: { pagination, backtotop, Tinymce },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
				security:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData:Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
                prop: "content_code",
                label: this.$t('admin_general.table_content'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			contentList: [],
			formList: [],
			componentList: [],
			dataList:{
				code:'',
                content_id:'',
                style:'',
                class:'',
                content:[],
				sort:10,
				xs:'',
				sm:'',
				md:'',
				lg:'',
				refer_id:'',
                status:'',
                security:''
			},
			permission:{
                ajaxTable:'NLNEI6',
                add:'2IPXOR',
                preview:'2IPXOR',
                DBadd:'5XIYKK',
                edit:'78DPZ7',
                DBedit:'RAIPIY',
                delete:'J8ZCBH',
                DBdelete:'CTYCN5'
            },
            permissionList:[],
            languageList:[]
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var result = postMethod('front/contentitem/ajaxTable',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.tableData = data.datatable.data;
						this.contentList = data.contentList;
						this.componentList = data.componentList;
						this.formList = data.formList;
						this.total = parseInt(data.datatable.total_number);
						this.listQuery.page = parseInt(data.datatable.current_pagination);
						this.listQuery.limit = parseInt(data.datatable.limit);
						this.buttonloading = false;
						this.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.code = '';
			this.dataList.contetn_id = '';
			this.dataList.component = '';
			this.dataList.style = '';
			this.dataList.class = '';
			this.dataList.xs = '';
			this.dataList.sm = '';
			this.dataList.md = '';
			this.dataList.lg = '';
			this.dataList.content = [];
			this.dataList.sort = 10;
			this.dataList.status = '';
			this.dataList.security = '';
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/contentitem/DBadd',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('addRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/contentitem/edit',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.dataList = data.thisDetail;
						this.$bvModal.show('editRow');
					}
					this.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/contentitem/DBedit',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('editRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('front/contentitem/DBdelete',this.postData);
					result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));
						if(value.valid){
							this.$message({
							type: 'success',
							message: data.returnMsg
							});
							this.buttonloading = false;
							this.initial();
						}else{
							this.errors = data.returnMsg;
							
							this.errors.forEach(function (value){
							text+= value+"<br/>";
							});
							
							this.$method.popupErrorMessage(this, text);
							this.buttonloading = false;
						}
					
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},getPreviewRow(){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = 1;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/contentitem/preview',this.postData);
				result.then((value) =>{
					// var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$bvModal.show('previewRow');
					}
					this.buttonloading = false;
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.initial();
	}
}
</script>